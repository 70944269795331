import { Link } from "react-router-dom";
import arttech_logo from "../../assets/arttech_logo.jpg";
import NavItems from "../../data/NavLink.js";
import { useState, Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosMenu } from "react-icons/io";


import { IoMdClose } from "react-icons/io";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function NavBar() {
  const [navOpen, setNavOpen] = useState(false);
const [onClick,setOnClick] = useState(false);
  // Function to toggle mobile navigation
  const toggleMobileNav = () => {
    setNavOpen(!navOpen);
  };

  // Function to close mobile navigation
  const closeMobileNav = () => {
    setNavOpen(!navOpen);
    setOnClick(!onClick);
  };
  const [open, setOpen] = useState(false);
  const [nav, setNav] = useState(false);

  function handleNav() {
    setNav(!nav);
  }

  return (
    <>
      <div className="bg-transparent hidden  md:flex flex-row justify-around items-center h-16 mt-1">
        {/* Logo */}
        <div>
          <Link to={"/"}>
            <img src={arttech_logo} alt="infraway_logo" className="w-20 h-12 rounded-xl" />
          </Link>
        </div>

        {/* NavItems */}
        <div className="flex justify-evenly items-center gap-10">
          {NavItems.map((item, i) => {
            if (item?.name !== "Products") {
              return (
                <Link to={`${item?.path}`} key={i}>
                  <p className="cursor-pointer text-lg font-semibold hover:text-orange-700 ">
                    {item?.name}
                  </p>
                </Link>
              );
            } else {
              return (
                <div >
              
                  <Menu as="div" className="relative inline-block text-left ">
                    <div>
                      <Menu.Button className="flex w-full justify-center items-center gap-x-1.5 rounded-md px-3 py-2 text-gray-900 cursor-pointer text-lg font-semibold hover:text-orange-700">
                         Products
                        <IoIosArrowDown
                          className="-mr-1 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
        
                          <Menu.Item>
                            {/* {({ active }) => (
                              <Link
                                to="/microsoft-office-365"
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "block px-4 py-2 text-sm"
                                )}
                              >
                                <p>Microsoft Office 365</p>
                              </Link>
                            )} */}
                            <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="inline-flex w-full  justify-between rounded-md   px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm">
                        Hardware Products
                        <IoIosArrowDown
                          className="-mr-1 h-5 w-5 ml-12 text-gray-400"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0  z-10 left-56 -top-2  mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                to="/desktop"
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "block px-4 py-2 text-sm"
                                )}
                              >
                                <p>Desktop</p>
                              </Link>
                            )}
                          </Menu.Item>
                        </div>
                        <div className="py-1">
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                to="/laptops"
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "block px-4 py-2 text-sm"
                                )}
                              >
                                <p>Laptops</p>
                              </Link>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                          </Menu.Item>
                        </div>
                        <div className="py-1">
                          <Menu.Item>
                            {/* {({ active }) => (
                              <Link
                                to="/google-workspace"
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "block px-4 py-2 text-sm"
                                )}
                              >
                                <p>Google Workplace Products</p>
                              </Link>
                            )} */}
                                <Menu as="div" className="relative inline-block text-left ">
                    <div>
                      <Menu.Button className="inline-flex w-full  justify-between rounded-md   px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm">
                        Digital Products
                        <IoIosArrowDown
                          className="-mr-1 h-5 w-5 ml-16 text-gray-400"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0  z-10 left-56 -top-2  mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                to="/microsoft-office-365"
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "block px-4 py-2 text-sm"
                                )}
                              >
                                <p>Microsoft Office 365</p>
                              </Link>
                            )}
                          </Menu.Item>
                        </div>
                        <div className="py-1">
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                to="/google-workspace"
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "block px-4 py-2 text-sm"
                                )}
                              >
                                <p>Google Workplace Products</p>
                              </Link>
                            )}
                            
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                  {/* ###############  Hardware products  ################### */}

                  
                </div>
              );
            }
          })}
        </div>
      </div>
{/* mobile nav  *********************************************/}
      
      <div className="md:hidden mt-4 mb-7">
        <div
          className={`fixed z-50 top-0  left-0 h-full w-full bg-white opacity-75 ${
            navOpen ? "block" : "hidden"
          }`}
        ></div>
        <Link to={"/"}>
          <img src={arttech_logo} alt="infraway_logo" className="w-18 h-12 rounded-xl" />
        </Link>

        <div
          className={`fixed z-50 top-0 left-0 h-full w-full bg-black overscroll-none opacity-80 transform ${
            navOpen
              ? "translate-x-0 ease-out transition-medium"
              : "-translate-x-full ease-in transition-medium"
          }`}
        >
          {/* <Link to="/">
            <img className="h-20 -ml-8 -mt-2" src={logo} alt="Main Logo" />
          </Link> */}
         <div className="flex flex-col items-left  gap-4 absolute z-10 p-2 w-screen h-screen">
  {NavItems.map((item, i) => {
    if (item?.name !== "Products") {
      return (
        <Link
          to={`${item?.path}`}
          key={i}
          className="cursor-pointer text-white text-lg font-semibold hover:text-orange-700"
          onClick={closeMobileNav}
        >
          {item?.name}
        </Link>
      );
    } else {
      return (
        <div key={i} className="flex flex-col ">
           {/* Hardware products */}
           <Menu as="div" className="relative inline-block text-left mt-2 mb-4 w-60">
            <div>
              <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-gray-300 hover:bg-gray-50">
                Hardware Products
                <IoIosArrowDown className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to="/desktop"
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        )}
                        onClick={closeMobileNav}
                      >
                        <p>Desktop</p>
                      </Link>
                    )}
                  </Menu.Item>
                </div>
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to="/laptops"
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        )}
                        onClick={closeMobileNav}
                      >
                        <p>Laptops</p>
                      </Link>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>

          <Menu as="div" className="relative inline-block text-left mt-2 mb-4 w-60">
            <div>
              <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-gray-300 hover:bg-gray-50">
                Digital Products
                <IoIosArrowDown className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to="/microsoft-office-365"
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        )}
                        onClick={closeMobileNav}
                      >
                        <p>Microsoft Office 365</p>
                      </Link>
                    )}
                  </Menu.Item>
                </div>
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to="/google-workspace"
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        )}
                        onClick={closeMobileNav}
                      >
                        <p>Google Workplace Products</p>
                      </Link>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>

         
        </div>
      );
    }
  })}
</div>

        </div>
      </div>
      

      {/* Mobile Navigation Toggle */}
      <div
        className="md:hidden fixed  z-50 top-0 mt-4 right-0 m-4 p-2 rounded-lg bg-white text-black cursor-pointer"
        onClick={toggleMobileNav}
      >
        {navOpen ? (
          <IoMdClose  className="w-8 h-8 "/>
          // <IoIosArrowDown  />
        ) : (
          <IoIosMenu className="w-8 h-8" />
        )}
      </div>

      <hr></hr>
    </>
  );
}
export default NavBar;
