const NavItem = [
    {
        name:"Home" ,
        path:"/"
    },
    {
        name:"About Us" ,
        path:"/about"
    },
    {
        name:"Contact Us" ,
        path:"/contact"
    },
    {
        name:"Products"
    }
    ]
    
    export default NavItem;