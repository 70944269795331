import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
// import video from "../assets/background_video (5).mp4";
import img from "../assets/img3.png";
import toast from 'react-hot-toast';
// import NavBar from "../Components/Common/NavBar";

const Contact = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm();

  const submitContactForm = async (data) => {
    console.log("Form Data - ", data);
    try {
      setLoading(true);
      const savedUserResponse = await fetch(
        `http://localhost:4000/api/v1/createuser`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...data }),
        }
      );
      console.log("User response", savedUserResponse);
      setLoading(false);
      if(savedUserResponse.success){
        toast.success("Your Details has been saved successfully");
      }
    } catch (error) {
      console.log("ERROR MESSAGE - ", error.message);
      console.log("ERROR - ", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({
        email: "",
        name: "",
        message: "",
        phoneNo: "",
      });
    }
  }, [reset, isSubmitSuccessful]);

  return (
    //  <div style={{backgroundImage:`url(${contact_bg})`,backgroundSize:'cover'}}>
    <div>
    {/* <NavBar/> */}
    <div className="mb-96 md:mb-0">
     
  
      {/* <video
          autoPlay
          muted
          loop
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 min-w-full min-h-full   w-auto h-auto"
        >
          <source src={video3} type="video/mp4" />
          Your browser does not support the video tag.
        </video> */}
        <div className="">

        </div>
      <div className="md:absolute  z-10 bg-opacity-80 md:p-4 rounded-lg p-2 overflow-x-hidden">
      <h1 className='head-text md:ml-12 mb-8 text-center'>Get in Touch</h1>
        <form
          className="flex flex-col gap-2 md:gap-5 md:mb-10 pb-10 md:ml-10 md:w-80  w-full sm:mx-auto"
          onSubmit={handleSubmit(submitContactForm)}
        >
          <div className="flex flex-col gap-2">
            <label htmlFor="name" className="text-black-500 font-semibold">
              Full Name
            </label>
            <input
              type="text"
              name="name"
              id="name"
              placeholder="John Smith"
              className="rounded-lg bg-richblack-700 p-3 text-[16px] leading-[24px] text-black shadow-[0_1px_0_0] shadow-white/50 border-solid border-gray-200 border-2 placeholder:text-richblack-400 focus:outline-none"
              {...register("name", { required: true })}
            />
            {errors.name && (
              <span className="-mt-1 text-[12px] text-yellow-100">
                Please enter your full name.
              </span>
            )}
          </div>

          <div className="flex flex-col gap-2">
            <label htmlFor="email" className="text-black-500 font-semibold">
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              placeholder=" John@gmail.com"
              className="rounded-lg bg-richblack-700 p-3 text-[16px] leading-[24px] text-black shadow-[0_1px_0_0] shadow-white/50 border-solid border-gray-200 border-2 placeholder:text-richblack-400 focus:outline-none"
              {...register("email", { required: true })}
            />
            {errors.email && (
              <span className="-mt-1 text-[12px] text-yellow-100">
                Please enter your Email address.
              </span>
            )}
          </div>

          <div className="flex flex-col gap-2">
            <label htmlFor="number" className="text-black-500 font-semibold">
              Phone Number
            </label>

            <div className="flex gap-5">
              <div className="flex w-[81px] flex-col gap-2">
                <div className="rounded-lg bg-richblack-700 p-3 text-[16px] leading-[24px] text-black shadow-[0_1px_0_0] border-solid border-gray-200 border-2 shadow-white/50 placeholder:text-richblack-400 focus:outline-none">
                  +91
                </div>
              </div>
              <div className="flex w-[calc(100%-90px)] flex-col gap-2">
                <input
                  type="number"
                  name="number"
                  id="number"
                  placeholder="637505 9***"
                  className="rounded-lg bg-richblack-700 p-3 text-[16px] leading-[24px] text-black shadow-[0_1px_0_0] border-solid border-gray-200 border-2 shadow-white/50 placeholder:text-richblack-400 focus:outline-none"
                  {...register("number", {
                    required: {
                      value: true,
                      message: " Phone Number.",
                    },
                    maxLength: { value: 12, message: "Invalid Phone Number" },
                    minLength: { value: 10, message: "Invalid Phone Number" },
                  })}
                />
              </div>
            </div>
            {errors.number && (
              <span className="-mt-1 text-[12px] text-yellow-100">
                {errors.number.message}
              </span>
            )}
          </div>

<div className="flex flex-col gap-2">
            <label htmlFor="company" className=" text-black-500 font-semibold ">
              Company
            </label>
            <input
              type="text"
              id="company"
              name="company"
              placeholder="art tech"
              className ="rounded-lg bg-richblack-700 p-3 text-[16px] leading-[24px] text-black shadow-[0_1px_0_0] shadow-white/50  border-solid border-gray-200 border-2 placeholder:text-richblack-400 focus:outline-none"
              {...register("company", { required: true })}
            />
            {errors.company && (
              <span className="-mt-1 text-[12px] text-yellow-100">
                Please enter your Company Name.              
                </span>
            )}
          </div>

          <div className="flex flex-col gap-2">
            <label htmlFor="message" className=" text-black-500 font-semibold ">
              Message
            </label>
            <textarea
              // name="message"
              // id="message"
              // cols="30"
              // rows="7"
              name="message"
              rows="3"
              placeholder="Write your thoughts here..."
              // placeholder="Enter your message here"
              className ="rounded-lg bg-richblack-700 p-3 text-[16px] leading-[24px] text-black shadow-[0_1px_0_0] shadow-white/50  border-solid border-gray-200 border-2 placeholder:text-richblack-400 focus:outline-none"
              {...register("message", { required: true })}
            />
            {errors.message && (
              <span className="-mt-1 text-[12px] text-yellow-100">
                Please enter your Message.
              </span>
            )}
          </div>

          <button
            disabled={loading}
            type="submit"
            className={`rounded-md bg-green-400 px-6 py-2 text-center text-[13px] font-bold text-black shadow-[2px_2px_0px_0px_rgba(255,255,255,0.18)] 
         ${
           !loading &&
           "transition-all duration-200 hover:scale-95 hover:shadow-none"
         }  disabled:bg-white00 sm:text-[16px] `}
          >
            Send Message
          </button>
        </form>
      </div>
      <img
        src={img}
        className="w-0 h-0 object-cover md:w-auto md:h-auto hidden md:block"
        // className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 min-w-full min-h-full m-full   md:w-auto md:h-auto  object-cover w-full h-full"
        // alt="img"
      />
    </div>
    </div>
  );
};

export default Contact;





















