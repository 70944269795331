import React from 'react';

const HoverComponent = () => {
  const columns = [
    { id: 1, text: 'Pricing', hoverContent: "Various subscription plans available based on user needs, with options for personal, business, and enterprise use.", icon: "A" },
    { id: 2, text: 'Integration', hoverContent: 'Seamless integration with other Microsoft products and services, such as Windows, Azure, and Dynamics 365.', icon: "A" },
    { id: 3, text: 'Security', hoverContent: 'Built-in security features including encryption, advanced threat protection, and compliance tools to safeguard data and privacy.', icon: "A" },
    { id: 4, text: 'Support', hoverContent: '24/7 customer support and access to online resources, tutorials, and community forums.', icon: "A" },
    { id: 5, text: 'Microsoft 365', hoverContent: 'A comprehensive suite of productivity tools and cloud services from Microsoft, designed to empower collaboration, communication, and productivity in the modern workplace.', icon: "A" },
    { id: 6, text: 'Google Workspace', hoverContent: 'Formerly known as G Suite, Google Workspace is a cloud-based productivity suite from Google, offering a collection of productivity tools and collaboration features for businesses and individuals.', icon: "A" }
  ];

  return (
    <div className="w-full p-4 mt-20 my-5 relative bgGradient">
      <div className="relative grid md:grid-cols-2 lg:grid-cols-2 grid-cols-1 gap-4 place-items-center place-content-center">
        {columns.map(({ id, text, hoverContent, icon }) => (
          <div key={id} className="border bg-white bg-opacity-90 rounded-lg p-4 relative z-10">
            <div className="flex justify-between items-center  gap-5 mt-4">
              <p className='text-bold'>{text}</p>
              <span className="w-10 h-10 bg-blue-500 hover:bg-blue-600 rounded-full duration-500 text-white text-center pt-1.5 font-semibold">
                {icon}
              </span>
            </div>
            <div>{hoverContent}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HoverComponent;
