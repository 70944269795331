function Demo() {
    return (
        <div className="flex">
            {/* <h1>Explore even more benefits of Microsoft 365 </h1> */}

            <div className="flex">
<h1>img</h1>
<p>hdfdhskf</p>

            </div>
            <div className="flex">
<h1>img</h1>
<p>hdfdhskf</p>

            </div>
            <div className="flex">
<h1>img</h1>
<p>hdfdhskf</p>

            </div>

            <div className="flex">
<h1>img</h1>
<p>hdfdhskf</p>

            </div>
            <div className="flex">
<h1>img</h1>
<p>hdfdhskf</p>

            </div>
            <div className="flex">
<h1>img</h1>
<p>hdfdhskf</p>

            </div>

        </div>
    )
}

export default Demo

