import { Checkmark } from 'react-checkmark'
import { FaBold } from 'react-icons/fa6';
import { IoCheckmarkOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

const GoogleWorkspacePlan = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className='my-5  '>

                <h2 className='text-center pt-2 pb-4'>Business Plans</h2>
                <div className='flex flex-row gap-10 justify-center flex-wrap'>
                    {/* business basics */}
                    <div className='border bg-white w-80 h-auto text-center hover:scale-105 transition-all 3s ease-in duration-1000 hover:border-blue-600 shadow-md rounded-md'>
                        <h2 className='pt-2 text-2xl font-semibold'>Business Starter</h2>
                        <p>Google Workspace</p>
                        <p className='pt-6 pb-4 text-blue-400 text-3xl font-bold'>₹ 137 / mo.</p>
                        <button onClick={()=>navigate("/contact")} className='border-2 bg-blue-500 rounded-md text-white w-48 p-2 font-semibold text-xl'>Contact Us</button>
                        <div className='pt-2 pl-10'>
                            <div className='bg-blue-600 w-[90%] h-[1px]'></div>
                        </div>

                        <div className='flex flex-col text-left px-10'>
                            <div className='flex flex-row items-center gap-2 mt-2'>
                                <div className='mt-2'>
                                        {/* <IoCheckmarkOutline className='mt-2' size='20px' fontWeight={FaBold} color='#0155ad' /> */}
                                        <Checkmark size='16px' color='#42A5F5' />
                                    </div>
                                <p>Custom and secure business email</p>
                            </div>

                            <div className='flex flex-row text-left gap-2 mt-2'>
                                <div className='mt-2'>
                                        {/* <IoCheckmarkOutline className='mt-2' size='20px' fontWeight={FaBold} color='#0155ad' /> */}
                                        <Checkmark size='16px' color='#42A5F5' />
                                    </div>
                                <p>100-participant video meetings</p>
                            </div>

                            <div className='flex flex-row gap-2 mt-2'>
                                <div className='mt-2'>
                                        {/* <IoCheckmarkOutline className='mt-2' size='20px' fontWeight={FaBold} color='#0155ad' /> */}
                                        <Checkmark size='16px' color='#42A5F5' />
                                    </div>
                                <p>30 GB storage per user</p>
                            </div>

                            <div className='flex flex-row gap-2 mt-2'>
                                <div className='mt-2'>
                                        {/* <IoCheckmarkOutline className='mt-2' size='20px' fontWeight={FaBold} color='#0155ad' /> */}
                                        <Checkmark size='16px' color='#42A5F5' />
                                    </div>
                                <p>Security and management controls</p>
                            </div>

                            <div className='flex flex-row gap-2 mt-2'>
                                <div className='mt-2'>
                                        {/* <IoCheckmarkOutline className='mt-2' size='20px' fontWeight={FaBold} color='#0155ad' /> */}
                                        <Checkmark size='16px' color='#42A5F5' />
                                    </div>
                                <p>Business-class email</p>
                            </div>

                            <div className='flex flex-row gap-2 mt-2 mb-4'>
                                <div className='mt-2'>
                                        {/* <IoCheckmarkOutline className='mt-2' size='20px' fontWeight={FaBold} color='#0155ad' /> */}
                                        <Checkmark size='16px' color='#42A5F5' />
                                    </div>
                                <p>Standard security</p>
                            </div>
                        </div>
                    </div>
                    {/* business standards */}
                    <div className='border bg-white w-80 h-auto text-center hover:scale-105 transition-all 3s ease-in duration-1000 hover:border-blue-600 shadow-md rounded-md'>
                        <h2 className='pt-2 text-2xl font-semibold'>Business Standard</h2>
                        <p>Google Workspace</p>
                        <p className='pt-6 pb-4 text-blue-400 text-3xl font-bold'>₹ 883 / mo.</p>
                        <button onClick={()=>navigate("/contact")} className='border-2 bg-blue-500 rounded-md text-white w-48 p-2 font-semibold text-xl'>Contact Us</button>
                        <div className='pt-2 pl-10'>
                            <div className='bg-blue-600 w-[90%] h-[1px]'></div>
                        </div>
                        <div>

                            <div className='flex flex-col text-left px-10'>
                                <div className='flex flex-row items-center gap-2 mt-2'>
                                     <div className='mt-2'>
                                        {/* <IoCheckmarkOutline className='mt-2' size='20px' fontWeight={FaBold} color='#0155ad' /> */}
                                        <Checkmark size='16px' color='#42A5F5' />
                                    </div>
                                    <p>Everything in Business Basic, plus:</p>
                                </div>

                                <div className='flex flex-row text-left gap-2 mt-2'>
                                     <div className='mt-2'>
                                        {/* <IoCheckmarkOutline className='mt-2' size='20px' fontWeight={FaBold} color='#0155ad' /> */}
                                        <Checkmark size='16px' color='#42A5F5' />
                                    </div>
                                    <p>Custom and secure business email</p>
                                </div>

                                <div className='flex flex-row gap-2 mt-2'>
                                     <div className='mt-2'>
                                        {/* <IoCheckmarkOutline className='mt-2' size='20px' fontWeight={FaBold} color='#0155ad' /> */}
                                        <Checkmark size='16px' color='#42A5F5' />
                                    </div>
                                    <p>150-participant video meetings + recording</p>
                                </div>

                                <div className='flex flex-row gap-2 mt-2'>
                                     <div className='mt-2'>
                                        {/* <IoCheckmarkOutline className='mt-2' size='20px' fontWeight={FaBold} color='#0155ad' /> */}
                                        <Checkmark size='16px' color='#42A5F5' />
                                    </div>
                                    <p>2 TB storage per user**</p>
                                </div>

                                <div className='flex flex-row gap-2 mt-2'>
                                     <div className='mt-2'>
                                        {/* <IoCheckmarkOutline className='mt-2' size='20px' fontWeight={FaBold} color='#0155ad' /> */}
                                        <Checkmark size='16px' color='#42A5F5' />
                                    </div>
                                    <p>Security and management controls</p>
                                </div>

                                <div className='flex flex-row gap-2 mt-2 mb-4'>
                                     <div className='mt-2'>
                                        {/* <IoCheckmarkOutline className='mt-2' size='20px' fontWeight={FaBold} color='#0155ad' /> */}
                                        <Checkmark size='16px' color='#42A5F5' />
                                    </div>
                                    <p>Standard support (paid upgrade to enhanced support)</p>
                                </div>
                            </div>

                        </div>
                    </div>
                    {/* business premium */}
                    <div className='border bg-white w-80 h-auto text-center hover:scale-105 transition-all 3s ease-in duration-1000 hover:border-blue-600 shadow-md rounded-md'>
                        <h2 className='pt-2 text-2xl font-semibold'>Business Plus</h2>
                        <p>Google Workspace</p>
                        <p className='pt-6 pb-4  text-blue-400 text-3xl font-bold'>₹ 1656 / mo.</p>
                        <button onClick={()=>navigate("/contact")} className='border-2 bg-blue-500 rounded-md text-white w-48 p-2 font-semibold text-xl'>Contact Us</button>
                        <div className='pt-2 pl-10'>
                            <div className='bg-blue-600 w-[90%] h-[1px]'></div>
                        </div>
                        <div>
                            <div className='flex flex-col text-left px-10'>
                                <div className='flex flex-row gap-2 mt-2'>
                                     <div className='mt-2'>
                                        {/* <IoCheckmarkOutline className='mt-2' size='20px' fontWeight={FaBold} color='#0155ad' /> */}
                                        <Checkmark size='16px' color='#42A5F5' />
                                    </div>
                                    <p>Everything in Business Standard, plus:</p>
                                </div>

                                <div className='flex flex-row gap-2 mt-2'>
                                     <div className='mt-2'>
                                        {/* <IoCheckmarkOutline className='mt-2' size='20px' fontWeight={FaBold} color='#0155ad' /> */}
                                        <Checkmark size='16px' color='#42A5F5' />
                                    </div>
                                    <p>Custom and secure business email + ediscovery, retention</p>
                                </div>

                                <div className='flex flex-row gap-2 mt-2'>
                                     <div className='mt-2'>
                                        {/* <IoCheckmarkOutline className='mt-2' size='20px' fontWeight={FaBold} color='#0155ad' /> */}
                                        <Checkmark size='16px' color='#42A5F5' />
                                    </div>
                                    <p>500 participant video meetings + recording, attendance tracking</p>
                                </div>

                                <div className='flex flex-row gap-2 mt-2'>
                                     <div className='mt-2'>
                                        {/* <IoCheckmarkOutline className='mt-2' size='20px' fontWeight={FaBold} color='#0155ad' /> */}
                                        <Checkmark size='16px' color='#42A5F5' />
                                    </div>
                                    <p>5TB storage per user**</p>
                                </div>

                                <div className='flex flex-row gap-2 mt-2'>
                                     <div className='mt-2'>
                                        {/* <IoCheckmarkOutline className='mt-2' size='20px' fontWeight={FaBold} color='#0155ad' /> */}
                                        <Checkmark size='16px' color='#42A5F5' />
                                    </div>
                                    <p>Enhanced security and management controls, including Vault and advanced endpoint management</p>
                                </div>

                                <div className='flex flex-row gap-2 mt-2 mb-4'>
                                     <div className='mt-2'>
                                        {/* <IoCheckmarkOutline className='mt-2' size='20px' fontWeight={FaBold} color='#0155ad' /> */}
                                        <Checkmark size='16px' color='#42A5F5' />
                                    </div>
                                    <p>Standard support (paid upgrade to enhanced support)</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GoogleWorkspacePlan;