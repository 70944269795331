import HomePageBanner_img from '../../assets/Homepage_Banner.jpg';
import Homepage_Banner_grid_img from '../../assets/Homepage_Banner_grid_img.jpg';
// import art_bg from '../../assets/Homepage_Banner2.jpg';
import microsoft_365 from  '../../assets/office-365-application-icons.webp';
import { useNavigate } from 'react-router-dom';
// import MsOfficePlan from './Components/Products/MsOfficePlan';
import MsOfficePlan from './MsOfficePlan';
import Demo from '../Common/Demo';


const MSOffice_Banner = () => {
  const navigate = useNavigate();
    return (
      <>
      <div
        className="relative  w-screen md:h-[600px] h-[600px]  md:mt-0 mt-4 rounded-sm mb-10"
        style={{ backgroundImage: `url(${microsoft_365})`, backgroundSize : "cover" }}
      >
        <div className="absolute   md:w-[45%] p-6 pt-8 flex flex-col justify-center">
          <p className="sm:text-base md:text-3xl text-3xl  -mt-2 lg:text-3xl font-semibold">Unlock a World of Possibilities: Microsoft Office 365 - Your Comprehensive Solution for Enhanced Productivity and Seamless Collaboration</p>
          <p className="mt-6 text-xl">Productivity Elevated, Solutions Unlimited: Providing Endless Possibilities for Every Business Challenge and Objective.</p>
          <button onClick={()=>navigate("/contact")} className="text-center border border-[#fe231f] font-semibold rounded-md text-white mt-6 md:mt-12  bg-[#fe231f]  p-2">See All Plans</button>
        </div>
      </div>
     
    <MsOfficePlan/> 
    {/* <Demo/> */}
        </>
    )
}

export default MSOffice_Banner;