import React from "react";
import Home_banner from "../../assets/home_banner.jpg";
import { useNavigate } from "react-router-dom";

const HomePageBanner = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="relative bg-white w-screen h-[600px] md:h-screen mt-4 rounded-sm mb-10 flex flex-col md:flex-row items-center">
        <div className="w-full md:w-[35%] h-full flex flex-col justify-center items-center text-center p-6 md:p-12 md:pt-18">
          <p className="text-2xl md:text-4xl -mt-6 font-mono font-semibold">
            Helping Organizations Manage Robust Applications with Our DevOps and DevSecOps
            Consulting Services.
          </p>
          <p className="mt-4 text-lg md:text-xl">
            We help organizations embrace DevOps and DevSecOps Consulting
            Services and improve the speed and security of applications.
          </p>
          <button
            onClick={() => navigate("/contact")}
            className="mt-8 md:mt-20 px-4 py-2 border border-[#fe231f] rounded-md text-[#fe231f] font-semibold hover:bg-[#fe231f] hover:text-white transition-colors duration-300"
          >
            Enquiry Now
          </button>
        </div>
        <div className="w-full md:w-[65%]  h-[400px]  md:h-full">
          <img
            src={Home_banner}
            className="w-full h-full object-cover"
            alt="homePage"
          />
        </div>
      </div>
    </>
  );
};

export default HomePageBanner;
