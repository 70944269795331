

import asus_laptop from 
'../../assets/Product_images/laptops/asus_laptop.jpg';
import lenovo_laptop from 
'../../assets/Product_images/laptops/lenovo_laptop.jpg';
import macBookAir from 
'../../assets/Product_images/laptops/macBookAir.jpg';
import Product from '../Common/Product';

const laptop = [
    {
        image : asus_laptop,
        name : "ASUS Vivobook 16",
        desc : " Intel Core i9-13900H 13th Gen, 16 (40.64 cm) FHD+, Thin & Light Laptop (16GB/512GB SSD/Intel Iris Xe/Win 11/Office 2021/Backlit KB/Fingerprint/Black/1.88 kg) ",
        price : "58,270",
        Quantity : 3
    },
    {
        image : lenovo_laptop,
         name : "Lenovo IdeaPad 1 ",
        desc : "Lenovo IdeaPad 1 AMD Ryzen 3 7320U 15.6 HD Thin and Light Laptop (8 GB/512GB SDD/Windows 11 Home/1Yr Warranty/Cloud Grey/1.58Kg), 82VG00EVIN ",
        price : "28,990",
        Quantity : 2
    },
   
    {
        image : macBookAir,
        name : "Apple MacBook Air",
        desc : `Laptop M1 chip, 13.3-inch/33.74 cm Retina Display, 8GB RAM, 256GB SSD Storage, Backlit Keyboard, FaceTime HD Camera, Touch ID. Works with iPhone/iPad; Silver`,
        price : "32,490",
        Quantity : 3
    }
]

console.log("desktops : ",laptop[0].image );

const Laptops = () => {
  
    return (
        <>
              <div className='w-[90%] mx-auto p-5 '>
            {
                laptop.map((item,index)=>(
                    <div className='flex md:flex-row bg-white flex-col justify-center  items-center gap-10 mb-5 p-1 border hover:border-blue-500'>
                        <div className='w-72 h-60'>
                            <img src={laptop[index].image} style={{backgroundSize:'cover',width:'100%', maxWidth:'230px'}}/>
                        </div>
                        <div className='flex flex-col gap-5'>
                            <p className='font-semibold'>{item.name}</p>
                            <p>{item.desc}</p>
                            <p className='text-green-500 font-semibold'>₹ {item.price}</p>
                            {/* <p>Quantity - {item.Quantity}</p> */}
                            <Product/>
                        </div>
                    </div>
                ))
            }
            </div>
        </>
    )
}

export default Laptops;