import React, { useState } from 'react';

const Product = ({ name, price }) => {
  const [quantity, setQuantity] = useState(1);

  const incrementQuantity = () => {
    setQuantity(quantity + 1);
  };

  const decrementQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  return (
    <div className=" p-4 mb-4">
      <h3 className="text-lg font-semibold">{name}</h3>
      {/* <p className="text-gray-500">Price: ${price}</p> */}
      <div className="mt-2 flex items-center">
        <button onClick={decrementQuantity} className="bg-gray-200 px-2 py-1 rounded-l">-</button>
        <span className="px-2">{quantity}</span>
        <button onClick={incrementQuantity} className="bg-gray-200 px-2 py-1 rounded-r">+</button>
      </div>
      <button className="mt-4 bg-blue-500 text-white px-4 py-2 rounded">Add to Cart</button>
    </div>
  );
};

export default Product;
