import React from "react";
import HighlightText from "../../Components/HomePage/HighlightText";
import CTAButton from "../../Components/HomePage/Button";



const LearningGrid = ({LearningGridArray}) => {
  return (
    <div className="grid mx-auto w-[350px] xl:w-fit grid-cols-1 xl:grid-cols-4 mb-12">
      {LearningGridArray.map((card, i) => {
        return (
          <div
            key={i}
            className={`${i === 0 && "xl:col-span-2 xl:h-[294px]"}  ${
              card.order % 2 === 1
                ? "bg-[#ECFDF5] h-[294px]"
                : card.order % 2 === 0
                ? "bg-[#dbd7c2] h-[294px]"
                : "bg-transparent"
            } ${card.order === 3 && "xl:col-start-2"}  `}
          >
            {card.order < 0 ? (
              <div className="xl:w-[90%] flex flex-col gap-3 pb-10 xl:pb-0">
                <div className="text-4xl font-semibold text-black">
                  {card.heading}
                  <HighlightText text={card.highliteText} />
                </div>
                <p className="text-black font-medium">
                  {card.description}
                </p>

                <div className="w-fit mt-2">
                  <CTAButton active={true} linkto={card.BtnLink}>
                    {card.BtnText}
                  </CTAButton>
                </div>
              </div>
            ) : (
              <div className="p-8 flex flex-col gap-8">
                <h1 className="text-black font-semibold text-lg">{card.heading}</h1>

                <p className="text-black">
                  {card.description}
                </p>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default LearningGrid;
