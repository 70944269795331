import Footer from '../Components/Common/Footer';
import NavBar from '../Components/Common/NavBar';
import HomePageBanner from '../Components/HomePage/HomePageBanner';
import MsOfficePlan from '../Components/Products/MsOfficePlan';
import GoogleWorkspacePlan from '../Components/Products/GoogleWorkspacePlan';
import HoverComponent from '../Components/Products/HoverComponent';
import LearningGrid from "../Components/About/LearningGrid";

const LearningGridArray = [
  {
    order: -1,
    heading: "World-Class Solutions for",
    highliteText: "Anyone, Anywhere",
    description:
      "At Art Tech, we specialize in a comprehensive range of services designed to support your IT infrastructure and streamline your operations:",
    BtnText: "Explore More",
    BtnLink: "/",
  },
  {
    order: 1,
    heading: "DevOps Services",
    description:
      "From continuous integration and continuous deployment (CI/CD) to infrastructure as code (IaC), we implement DevOps best practices to accelerate your software development lifecycle and improve release quality.",
  },
  {
    order: 2,
    heading: "Cloud Solutions",
    description:
      "Our experts help you navigate the complexities of cloud computing, offering services in cloud migration, management, and optimization to ensure your business leverages the full potential of the cloud.",
  },
  {
    order: 3,
    heading: "IT Consulting",
    description:
      "We provide strategic IT consulting services to help you align your technology initiatives with your business goals. Our team offers insights and solutions tailored to your specific challenges and opportunities.",
  },
  {
    order: 4,
    heading: `Managed IT Services`,
    description:
      "Focus on your core business while we take care of your IT needs. Our managed services include 24/7 monitoring, support, and maintenance to ensure your systems are always running smoothly.",
  },
  {
    order: 5,
    heading: "Security Services",
    description:
      "Protect your business with our comprehensive security solutions. We offer vulnerability assessments, threat detection, and incident response to safeguard your data and systems from cyber threats."
  },
];

function Home() {
  return (
    <>
      <HomePageBanner/>
      <HoverComponent/>
      <div className='p-12'>
      <LearningGrid LearningGridArray = {LearningGridArray} />
      </div>
      <h1 className='text-3xl text-center pt-5'>Microsoft 365 makes your Business Professional</h1>
      <MsOfficePlan/>
      {/* <Threed/> */}
      <h1 className='text-3xl text-center pt-5'>Explore Google Workspace Pricing Options
      </h1>
     
      <p className='text-2xl text-center'>Our Google Workspace pricing and plans are designed to suit your budget and business needs</p>
      <GoogleWorkspacePlan/>
    </>
    
  );
}

export default Home;