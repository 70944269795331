import HomePageBanner_img from '../../assets/Homepage_Banner.jpg';
import Homepage_Banner_grid_img from '../../assets/Homepage_Banner_grid_img.jpg';
// import art_bg from '../../assets/Homepage_Banner2.jpg';
import googleWorkspace_img from  '../../assets/google_workspace.webp';
import { useNavigate } from 'react-router-dom';
// import MsOfficePlan from './Components/Products/MsOfficePlan';
import GoogleWorkspacePlan from './GoogleWorkspacePlan';


const GoogleWorkspace_Banner = () => {
  const navigate = useNavigate();
    return (
        <>
    <div
      className="relative  w-screen md:h-[600px] h-[600px]  md:mt-0 mt-2 rounded-sm mb-10"
      style={{ backgroundImage: `url(${googleWorkspace_img})`, backgroundSize : "cover" }}
    >
      <div className="absolute   md:w-[45%] p-6 pt-8 flex flex-col justify-center">
          <p className="sm:text-base md:text-3xl text-3xl  -mt-2 lg:text-3xl text-white font-semibold">Experience the Future of Work with Google Workspace: Drive Success with Innovative and Collaborative Solutions.</p>
          <p className="mt-6 text-xl text-white">Boost Your Productivity with Google Workspace: A Comprehensive Suite for Modern Businesses.</p>
          <button onClick={()=>navigate("#all_plans")} className="text-center border border-[#fe231f] font-semibold rounded-md text-white mt-6 md:mt-12  bg-[#fe231f]  p-2">See All Plans</button>
        </div>
    </div>
    <div id='all_plans'>
    <GoogleWorkspacePlan/> 
    </div>
        </>
    )
}

export default GoogleWorkspace_Banner;