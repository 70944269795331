import { Checkmark } from 'react-checkmark'
import { FaBold } from 'react-icons/fa6';
import { IoCheckmarkOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import lenovo_ideacentre from 
'../../assets/Product_images/desktop/Lenovo_IdeaCentre.jpg';
import hp_pc from 
'../../assets/Product_images/desktop/HP_PC.jpg';
import asus_vivo from 
'../../assets/Product_images/desktop/Asus_vivo.jpg';
import Product from '../Common/Product';

const desktops = [
    {
        image : lenovo_ideacentre,
        name : "Lenovo PC",
        desc : "Lenovo IdeaCentre AIO 3 11th Gen Intel i5 23.8 FHD IPS 3-Side Edgeless All-in-One Desktop with Alexa Built-in (8GB/1 TB HDD/Windows 11 Home/MS Office 2021/Wireless Keyboard & Mouse)",
        price : "58,270",
        Quantity : 3
    },
    {
        image : hp_pc,
         name : "HP PC",
        desc : "HP All-in-One PC Intel Pentium J5040 21.5-inch(54.6 cm) FHD Three-Sided Micro-Edge Display(8GB RAM/512GB SSD/Intel UHD Graphics/Win 11 Home/Wired Keyboard and Mouse Combo/MS Office), ",
        price : "28,990",
        Quantity : 2
    },
    {
        image : lenovo_ideacentre,
        name : "Lenovo PC",
        desc : "Lenovo IdeaCentre AIO 3 11th Gen Intel i5 23.8 FHD IPS 3-Side Edgeless All-in-One Desktop with Alexa Built-in (8GB/1 TB HDD/Windows 11 Home/MS Office 2021/Wireless Keyboard & Mouse)",
        price : "58,270",
        Quantity : 3
    },
    {
        image : asus_vivo,
        name : "ASUS Vivo",
        desc : `ASUS Vivo AiO V222, 4 core Intel Pentium Silver J5040, 21.5" (54.61cm), All-in-One Desktop (8GB/256GB SSD/Win11/1Year McAfee Security/Wired Keyboard & Mouse Included/Black/4.8 kg)`,
        price : "32,490",
        Quantity : 3
    }
]

console.log("desktops : ",desktops[0].image );

const Desktop = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className='w-[90%] mx-auto p-5 '>
            {
                desktops.map((item,index)=>(
                    <div className='flex md:flex-row flex-col    bg-white  rounded-md justify-center items-center gap-10 mb-5 p-1 border hover:border-blue-500'>
                        <div className='w-72 h-60'>
                            <img src={desktops[index].image} style={{backgroundSize:'cover',width:'100%'}}/>
                        </div>
                        <div className='flex flex-col gap-5'>
                            <p className='font-semibold'>{item.name}</p>
                            <p>{item.desc}</p>
                            <p className='text-green-500 font-semibold'>₹ {item.price}</p>
                            {/* <p>Quantity - {item.Quantity}</p> */}
                            <Product/>
                        </div>
                    </div>
                ))
            }
            </div>
        </>
    )
}

export default Desktop;