import React from "react"

import FoundingStory from "../assets/FoundingStory.png"
import BannerImage1 from "../assets/aboutus1.webp"
import BannerImage2 from "../assets/aboutus2.webp"
import BannerImage3 from "../assets/aboutus3.webp"
import LearningGrid from "../Components/About/LearningGrid"
import Quote from "../Components/About/Quote"
import HighlightText from "../Components/HomePage/HighlightText"

const LearningGridArray = [
  {
    order: -1,
    heading: "World-Class Solutions for",
    highliteText: "Anyone, Anywhere",
    description:
      "At Art Tech, we specialize in a comprehensive range of services designed to support your IT infrastructure and streamline your operations:",
    BtnText: "Explore More",
    BtnLink: "/",
  },
  {
    order: 1,
    heading: "DevOps Services",
    description:
      "From continuous integration and continuous deployment (CI/CD) to infrastructure as code (IaC), we implement DevOps best practices to accelerate your software development lifecycle and improve release quality.",
  },
  {
    order: 2,
    heading: "Cloud Solutions",
    description:
      "Our experts help you navigate the complexities of cloud computing, offering services in cloud migration, management, and optimization to ensure your business leverages the full potential of the cloud.",
  },
  {
    order: 3,
    heading: "IT Consulting",
    description:
      "We provide strategic IT consulting services to help you align your technology initiatives with your business goals. Our team offers insights and solutions tailored to your specific challenges and opportunities.",
  },
  {
    order: 4,
    heading: `Managed IT Services`,
    description:
      "Focus on your core business while we take care of your IT needs. Our managed services include 24/7 monitoring, support, and maintenance to ensure your systems are always running smoothly.",
  },
  {
    order: 5,
    heading: "Security Services",
    description:
      "Protect your business with our comprehensive security solutions. We offer vulnerability assessments, threat detection, and incident response to safeguard your data and systems from cyber threats."
  },
];



const About = () => {
  return (
    <div>
      <section className="bg-zinc-700">
        <div className="relative mx-auto flex w-11/12 max-w-maxContent flex-col justify-between gap-10 text-center text-white">
          <header className="mx-auto py-20 text-4xl font-semibold lg:w-[70%]">
          Optimizing IT Performance with Cutting-Edge
            <HighlightText text={"DevOps Solutions"} />
            <p className="mx-auto mt-3 text-center text-base font-medium text-zinc-300 lg:w-[95%]">
            </p>
          </header>
          <div className="sm:h-[70px] lg:h-[150px]"></div>
          <div className="absolute bottom-0 left-[50%] grid w-[100%] translate-x-[-50%] translate-y-[30%] grid-cols-3 gap-3 lg:gap-5">
            <img src={BannerImage1} alt="" />
            <img src={BannerImage2} alt="" />
            <img src={BannerImage3} alt="" />
          </div>
        </div>
      </section>

      <section className="border-b border-zinc-700">
        <div className="mx-auto flex w-11/12 max-w-maxContent flex-col justify-between gap-10 text-black">
          <div className="h-[100px] "></div>
          <Quote />
        </div>
      </section>

      <section>
        <div className="mx-auto flex w-11/12 max-w-maxContent flex-col justify-between gap-10 text-black">
          <div className="flex flex-col items-center gap-10 lg:flex-row justify-between">
            <div className="my-24 flex lg:w-[50%] flex-col gap-10">
              <h1 className="bg-gradient-to-br from-[#833AB4] via-[#FD1D1D] to-[#FCB045] bg-clip-text text-4xl font-semibold text-transparent lg:w-[70%] ">
              Our Approach
              </h1>
              <p className="text-base font-medium text-black lg:w-[95%]">
              We believe in a customer-centric approach, where your success is our priority. Our process is collaborative and transparent, ensuring that you are involved at every stage. We start by understanding your business objectives, then design and implement solutions that are tailored to your needs. Our commitment to continuous improvement means we are always looking for ways to optimize and enhance your IT environment.
              </p>
              <p className="text-base font-medium text-black lg:w-[95%]">
              Our team is composed of highly skilled professionals with diverse backgrounds in IT and DevOps. We bring a wealth of experience and a passion for technology to every project. Meet the experts who are driving innovation and delivering exceptional results for our clients.
              </p>
            </div>

            <div>
              <img
                src={FoundingStory}
                alt=""
                className="shadow-[0_0_20px_0] shadow-[#FC6767]"
              />
            </div>
          </div>
          <div className="flex flex-col items-center lg:gap-10 lg:flex-row justify-between">
            <div className="my-24 flex lg:w-[40%] flex-col gap-10">
              <h1 className="bg-gradient-to-b from-[#FF512F] to-[#F09819] bg-clip-text text-4xl font-semibold text-transparent lg:w-[70%] ">
                Our Vision
              </h1>
              <p className="text-base font-medium text-black lg:w-[95%]">
              Our vision is to be the leading provider of DevOps and IT services, recognized globally for our innovative solutions, exceptional service, and commitment to excellence. We aspire to transform the IT landscape by driving digital transformation and enabling businesses to harness the full potential of technology in achieving sustainable growth and success.
              </p>
            </div>
            <div className="my-24 flex lg:w-[40%] flex-col gap-10">
              <h1 className="bg-gradient-to-b from-[#1FA2FF] via-[#12D8FA] to-[#A6FFCB] text-transparent bg-clip-text text-4xl font-semibold lg:w-[70%] ">
              Our Mission
              </h1>
              <p className="text-base font-medium text-black lg:w-[95%]">
              Our mission is to deliver robust, scalable, and secure IT solutions that enhance business agility and operational excellence. We strive to bridge the gap between development and operations, fostering a culture of collaboration and continuous improvement. Our goal is to empower our clients to achieve their business objectives through innovative technology and strategic guidance.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* <StatsComponenet /> */}
      <section className="mx-auto mt-20 flex w-11/12 max-w-maxContent flex-col justify-between gap-10 text-white">
        <LearningGrid LearningGridArray = {LearningGridArray} />
      </section>
    </div>
  )
}

export default About
