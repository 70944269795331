import { Checkmark } from 'react-checkmark'
import { FaBold } from 'react-icons/fa6';
import { IoCheckmarkOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import {MSOffice_Banner} from '../../Components/Products/MSOffice_Banner.jsx';


const MsOfficePlan = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className='my-5 '>

                <h2 className='text-center pt-2 pb-4'>Business Plans</h2>
                <div className='flex flex-row gap-10 justify-center flex-wrap'>
                    {/* business basics */}
                    <div className='border bg-white w-80 h-auto text-center hover:scale-105 transition-all 3s ease-in duration-1000 hover:border-blue-400 border-2 shadow-md rounded-md'>
                        <h2 className='pt-2 text-2xl font-semibold'>Business Basic</h2>
                        <p>Microsoft 365</p>
                        <p className='pt-6 pb-4 text-blue-400 text-3xl font-bold'>₹ 133 / mo.</p>
                        <button onClick={()=>navigate("/contact")} className='border-2 bg-blue-500 rounded-md  text-white w-48 p-2 font-semibold text-xl'>Contact Us</button>
                        <div className='pt-2 pl-10'>
                            <div className='bg-blue-600 w-[90%] h-[1px]'></div>
                        </div>

                        <div className='flex flex-col text-left px-10'>
                            <div className='flex flex-row items-center gap-2 mt-2'>
                                <div className='mt-2'>
                                    {/* <IoCheckmarkOutline size='18px' fontWeight={FaBold} color='#0155ad' /> */}
                                    <Checkmark size='16px' color='#42A5F5' />
                                </div>
                                <p>Web and mobile apps and services</p>
                            </div>

                            <div className='flex flex-row text-left gap-2 mt-2 '>
                                <div className='mt-2'>
                                <Checkmark size='16px' color='#42A5F5' />
                                </div>
                                <p>Web and mobile versions of Office apps only</p>
                            </div>

                            <div className='flex flex-row gap-2 mt-2 '>
                            <div className='mt-2'>
                                    {/* <IoCheckmarkOutline size='18px' fontWeight={FaBold} color='#0155ad' /> */}
                                    <Checkmark size='16px' color='#42A5F5' />
                                </div>
                                <p>Chat, call, meet up to 300 attendees</p>
                            </div>

                            <div className='flex flex-row gap-2 mt-2'>
                                <div className='mt-2'>
                                    {/* <IoCheckmarkOutline className='mt-2' size='20px' fontWeight={FaBold} color='#0155ad' /> */}
                                    <Checkmark size='16px' color='#42A5F5' />
                                </div>
                                <p>1 TB of cloud storage per user</p>
                            </div>

                            <div className='flex flex-row gap-2 mt-2'>
                                <div className='mt-2'>
                                    {/* <IoCheckmarkOutline className='mt-2' size='20px' fontWeight={FaBold} color='#0155ad' /> */}
                                    <Checkmark size='16px' color='#42A5F5' />
                                </div>
                                <p>Business-class email</p>
                            </div>

                            <div className='flex flex-row gap-2 mt-2'>
                                <div className='mt-2'>
                                    {/* <IoCheckmarkOutline className='mt-2' size='20px' fontWeight={FaBold} color='#0155ad' /> */}
                                    <Checkmark size='16px' color='#42A5F5' />
                                </div>
                                <p>Standard security</p>
                            </div>

                            <div className='flex flex-row gap-2 mt-2 pt-2 mb-4'>
                                <div className='mt-2'>
                                    {/* <IoCheckmarkOutline className='mt-2' size='20px' fontWeight={FaBold} color='#0155ad' /> */}
                                    <Checkmark size='16px' color='#42A5F5' />
                                </div>
                                <p>Anytime phone and web support</p>
                            </div>

                        </div>
                    </div>
                    {/* business standards */}
                    <div className='border bg-white w-80 h-auto text-center hover:scale-105 transition-all 3s ease-in duration-1000 hover:border-blue-400 border-2 shadow-md rounded-md'>
                        <h2 className='pt-2 text-2xl font-semibold'>Business Standard</h2>
                        <p>Microsoft 365</p>
                        <p className='pt-6 pb-4 text-blue-400 text-3xl font-bold'>₹ 708 / mo.</p>
                        <button onClick={()=>navigate("/contact")} className='border-2 bg-blue-500 rounded-md text-white w-48 p-2 font-semibold text-xl'>Contact Us</button>
                        <div className='pt-2 pl-10'>
                            <div className='bg-blue-600 w-[90%] h-[1px]'></div>
                        </div>
                        <div>

                            <div className='flex flex-col text-left px-10'>
                                <div className='flex flex-row items-center gap-2 mt-2'>
                                    <div className='mt-2'>
                                        {/* <IoCheckmarkOutline className='mt-2' size='20px' fontWeight={FaBold} color='#0155ad' /> */}  <Checkmark size='16px' color='#42A5F5' />
                                    </div>
                                    <p>Everything in Business Basic, plus:</p>
                                </div>

                                <div className='flex flex-row text-left gap-2 mt-2'>
                                    <div className='mt-2'>
                                        {/* <IoCheckmarkOutline className='mt-2' size='20px' fontWeight={FaBold} color='#0155ad' /> */}  <Checkmark size='16px' color='#42A5F5' />
                                    </div>
                                    <p>Desktop, web, and mobile apps and services</p>
                                </div>

                                <div className='flex flex-row gap-2 mt-2'>
                                    <div className='mt-2'>
                                        {/* <IoCheckmarkOutline className='mt-2' size='20px' fontWeight={FaBold} color='#0155ad' /> */}  <Checkmark size='16px' color='#42A5F5' />
                                    </div>
                                    <p>Desktop versions of Office apps with premium features</p>
                                </div>

                                <div className='flex flex-row gap-2 mt-2'>
                                    <div className='mt-2'>
                                        {/* <IoCheckmarkOutline className='mt-2' size='20px' fontWeight={FaBold} color='#0155ad' /> */}  <Checkmark size='16px' color='#42A5F5' />
                                    </div>
                                    <p>Easily host webinars</p>
                                </div>

                                <div className='flex flex-row gap-2 mt-2'>
                                    <div className='mt-2'>
                                        {/* <IoCheckmarkOutline className='mt-2' size='20px' fontWeight={FaBold} color='#0155ad' /> */}  <Checkmark size='16px' color='#42A5F5' />
                                    </div>
                                    <p>Attendee registration and reporting tools</p>
                                </div>

                                <div className='flex flex-row gap-2 mt-2 mb-4'>
                                    <div className='mt-2'>
                                        {/* <IoCheckmarkOutline className='mt-2' size='20px' fontWeight={FaBold} color='#0155ad' /> */}  <Checkmark size='16px' color='#42A5F5' />
                                    </div>
                                    <p>Manage customer appointments</p>
                                </div>
                            </div>

                        </div>
                    </div>
                    {/* business premium */}
                    <div className='border bg-white w-80 h-auto text-center hover:scale-105 transition-all 3s ease-in duration-1000 hover:border-blue-400 border-2 shadow-md rounded-md'>
                        <h2 className='pt-2 text-2xl font-semibold'>Business Premium</h2>
                        <p>Microsoft 365</p>
                        <p className='pt-6 pb-4  text-blue-400 text-3xl font-bold'>₹ 1684 / mo.</p>
                        <button onClick={()=>navigate("/contact")} className='border-2 bg-blue-500 text-white rounded-md w-48 p-2 font-semibold text-xl'>Contact Us</button>
                        <div className='pt-2 pl-10'>
                            <div className='bg-blue-600 w-[90%] h-[1px]'></div>
                        </div>
                        <div>
                            <div className='flex flex-col text-left px-10'>
                                <div className='flex flex-row gap-2 mt-2'>
                                    <div className='mt-2'>
                                        {/* <IoCheckmarkOutline className='mt-2' size='20px' fontWeight={FaBold} color='#0155ad' /> */}
                                        <Checkmark size='16px' color='#42A5F5' />
                                    </div>
                                    <p>Everything in Business Standard, plus:</p>
                                </div>

                                <div className='flex flex-row gap-2 mt-2'>
                                    <div className='mt-2'>
                                        {/* <IoCheckmarkOutline className='mt-2' size='20px' fontWeight={FaBold} color='#0155ad' /> */}
                                        <Checkmark size='16px' color='#42A5F5'  />
                                    </div>
                                    <p>Advanced Security</p>
                                </div>

                                <div className='flex flex-row gap-2 mt-2'>
                                    <div className='mt-2'>
                                        {/* <IoCheckmarkOutline className='mt-2' size='20px' fontWeight={FaBold} color='#0155ad' /> */}
                                        <Checkmark size='16px' color='#42A5F5' />
                                    </div>
                                    <p>Access and Data Control</p>
                                </div>

                                <div className='flex flex-row gap-2 mt-2 mb-4'>
                                    <div className='mt-2'>
                                        {/* <IoCheckmarkOutline className='mt-2' size='20px' fontWeight={FaBold} color='#0155ad' /> */}
                                        <Checkmark size='16px' color='#42A5F5' />
                                    </div>
                                    <p>CyberThreat Protection</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
    
        </>
    )
}

export default MsOfficePlan;