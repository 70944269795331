import React from "react";
import { SocialIcon } from "react-social-icons";

const Footer = () => {
  return (
    <div className="md:h-[350px] md:mr-36 md:ml-36 mb-12 rounded-md md:mt-20 mt-2 bg-white align-middle md:flex justify-around grid grid-cols-1 md:w-auto sm:w-full h-full">
      <div className=" mt-12 text-center md:text-left">
        <h1 className="text-[#5d5e6f] font-semibold">ArtTech</h1>
        <h2 className="text-[#5d5e6f] mt-4 font-semibold">
          © 2024 Art Technologies. All rights reserved.
        </h2>
      </div>
      <div className=" mt-12 text-center md:text-left">
        <h2 className="mt-3 text-[#5d5e6f] font-semibold">Home</h2>
        <h2 className="mt-3 text-[#5d5e6f] font-semibold">FAQs</h2>
        <h2 className="mt-3 text-[#5d5e6f] font-semibold">Policy</h2>
        <h2 className="mt-3 text-[#5d5e6f] font-semibold">Blog</h2>
      </div>
      <div className=" mt-12 text-center md:text-left">
        <h2 className="text-[#5d5e6f] font-semibold">Social</h2>
        <div className="mt-4">
          <SocialIcon
            url="https://facebook.com/"
            className="mr-4"
            target="_blank"
            rel="noopener noreferrer"
          />
          <SocialIcon
            url="https://twitter.com/"
            className="mr-4"
            target="_blank"
            rel="noopener noreferrer"
          />
          <SocialIcon
            url="https://instagram.com/"
            className="mr-4"
            target="_blank"
            rel="noopener noreferrer"
          />
          <SocialIcon
            url="https://vimeo.com/"
            className="mr-4"
            target="_blank"
            rel="noopener noreferrer"
          />
          {/* <SocialIcon
            url="https://meetup.com/"
            className="mr-4"
            target="_blank"
            rel="noopener noreferrer"
          /> */}
        </div>
      </div>
    </div>
  );
};

export default Footer;
