import { Route, Routes } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import './App.css';
import Home from './pages/Home';
import Footer from './Components/Common/Footer';
// import MsOfficePlan from './Components/Products/MsOfficePlan';
import MSOffice_Banner from './Components/Products/MSOffice_Banner';
import GoogleWorkspace_Banner from './Components/Products/GoogleWorkspace_Banner';
import Desktop from './Components/Products/Desktop';
import Laptops from './Components/Products/Laptops';
import Contact from './pages/Contact';
import About from './pages/About';
import NavBar from './Components/Common/NavBar';
import whatsapplogo from './assets/whatsapplogo.png';



function App() {

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);


  return (
    <div className='w-full h-full'>
      <NavBar/>
      <Routes>
        <Route path='/' element={<Home/>}/> 
        <Route path='/about' element={<About/>}/> 
        <Route path='/contact' element={<Contact/>}/> 
        <Route path='/microsoft-office-365' element={<MSOffice_Banner/>}/> 
        <Route path='/google-workspace' element={<GoogleWorkspace_Banner/>}/> 
        <Route path='/desktop' element={<Desktop/>}/> 
        <Route path='/laptops' element={<Laptops/>}/> 
      </Routes>   
      <div className='fixed bottom-10 right-5 align-text-bottom'>
                <a href='https://wa.me/917014552341' target='_blank' rel="noopener noreferrer">
                    <img src={whatsapplogo} width="60" alt='whatsapp logo' />
                </a>
      </div>
      <Footer/>
    </div>
    
  );
}

export default App;
